export default class selectLink {
    constructor() {

        // this.winW;
        // this.winH;

        this.$select;
        this.url;

    }

    init() {
        this.$select = document.querySelectorAll('.js__select-link');

        for (let i = 0; i < this.$select.length; i++) {
            this.$select[i].addEventListener('change', event => {
                this.url = event.target.value;
                // console.log(this.url);
                location.href = this.url;
            })
        }
    }
    // resize(w, h) {
    //     this.winW = w;
    //     this.winH = h;
    // }
}