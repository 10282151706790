import { create } from "domain";

export default class calendar {
  constructor() {}
  init() {
    let calendarNav = document.querySelector(".my-calendar-header");
    let calendarCate = document.querySelector(".category-key");
    let calendarTable = document.querySelector(".my-calendar-table");
    let calendarMonth = document.querySelector(".my-calendar-month");
    let jdcalendar = document.querySelector("#jd-calendar");
    let pCalendarTable = document.createElement("div");
    calendarCate.after(calendarNav);
    pCalendarTable.className = "p-calendar-table";
    jdcalendar.appendChild(pCalendarTable);
    pCalendarTable.appendChild(calendarTable);
    pCalendarTable.parentNode.insertBefore(calendarMonth, pCalendarTable);
    document.querySelector(".prevMonth").innerText = "前月";
    document.querySelector(".nextMonth").innerText = "翌月";

    /* ------------------------------------------------------------
     * [ calendar sort ]
     * ------------------------------------------------------------ */
    $(function () {
      if ($(".category-key")[0]) {
        //ボタン部分並び替え
        var _ul = $(".category-key ul"),
          _kibe = $(".category-key .cat_kibe"),
          _kido = $(".category-key .cat_kido"),
          _namiki = $(".category-key .cat_namiki"),
          _kitamura = $(".category-key .cat_kitamura"),
          _masuda = $(".category-key .cat_masuda"),
          _yanagawa = $(".category-key .cat_yanagawa");
        _ul.prepend(_kitamura);
        _ul.prepend(_namiki);
        _ul.prepend(_masuda);
        _ul.prepend(_kido);
        _ul.prepend(_yanagawa);
        _ul.prepend(_kibe);
        $("#calendar .wrapper").fadeIn(100);

        //[ 表示切り替え ]
        $("#calendar .category-key li").click(function () {
          var _target = $(this).attr("class");
          _target = _target.replace("cat", "mc");
          $("#calendar .calendar-event").css("display", "none");
          $("#calendar")
            .find(".calendar-event." + _target)
            .css("display", "block");
          $("#calendar .category-key li").css({
            opacity: 0.5,
            "font-weight": "normal",
          });
          $(this).css({ opacity: 1, "font-weight": "bold" });
          return false;
        });

        $("#calendar li.cat_all").click(function () {
          $("#calendar .calendar-event").css("display", "block");
          $("#calendar .category-key li").css({
            opacity: 1,
            "font-weight": "normal",
          });
          return false;
        });
      }
    });
  }
}
