// import TweenMax from 'gasp';
export default class lowLayerTabSwitch {
  constructor(header,body) {
    this.$target = [];
    this.$header = header;
    this.$body = body;
    this.$lBody = document.getElementById('l-body');
    this.$lHeader = document.getElementById('l-header');
  }

  init() {
    this.$tabs = document.querySelectorAll('.js__tab-contents');
    this.$tabs = [...this.$tabs];
    this.$currentTab = '';

    this.$tabButtons = document.querySelectorAll('.js__tab-btn');
    this.$tabButtons = [...this.$tabButtons];
    this.$currentButton = ''

    this.$imageNav = document.querySelectorAll('.js__image-nav');
    this.$imageNav = [...this.$imageNav];
    this.$currentImageNav = '';

    this.$tabs[0].classList.add('show');

    this.$lowlayerNav = document.querySelector(".c-lowlayer_nav");
    const rangeLeftNav = document.querySelector(".c-lowlayer_nav-list li .js__tab-btn").offsetLeft;



    for (const tabs of this.$tabButtons) {
      tabs.addEventListener('click', (el) => {
        el.preventDefault();

        TweenMax.set(this.$header, {opacity: 0, y: -70});
        TweenMax.set(this.$body, {opacity: 0});
        this.$header.style.opacity = 0;
        TweenMax.to(this.$header, 1.2, {
          y: 0,
          opacity: 1,
        });
        TweenMax.to(this.$body, 1.6, {
          opacity: 1,
          delay:0.3,
          // ease: Power2.easeOut,
        });

        this.$currentButton = this.$tabButtons.find(button => button.parentNode.classList.contains('is-current'));
        if(this.$currentButton === el.currentTarget){
          return;
        };
        this.$currentButton.parentNode.classList.remove('is-current');

        const tab = el.currentTarget.dataset.tab;
        this.$currentTab = this.$tabs.find(tab => tab.classList.contains('show'));
        const dispTab = document.getElementById(tab);
        dispTab.classList.add("show");

        el.currentTarget.parentNode.classList.add('is-current');
        this.$currentTab.classList.remove('show');

        if(document.getElementsByTagName('body')[0].classList.contains('page-template-sp_doctor-php')){
          for(const nav of this.$imageNav){
            nav.classList.remove('is-selected');
          }
          this.$currentImageNav = this.$imageNav.find(doctor => doctor.dataset.tab === el.currentTarget.dataset.tab)
          this.$currentImageNav.classList.add('is-selected');
        }
        const rangeTarget = el.currentTarget.offsetLeft;
        this.$lowlayerNav.scrollLeft = (rangeTarget-rangeLeftNav);
      })
    }
    if(document.getElementsByTagName('body')[0].classList.contains('page-template-sp_doctor-php')){

      for (const tabs of this.$imageNav) {
        tabs.addEventListener('click',(el) => {

          TweenMax.set(this.$header, {opacity: 0, y: -70});
          TweenMax.set(this.$body, {opacity: 0});
          this.$header.style.opacity = 0;
          TweenMax.to(this.$header, 1.2, {
            y: 0,
            opacity: 1,
          });
          TweenMax.to(this.$body, 1.6, {
            opacity: 1,
            delay:0.3,
            // ease: Power2.easeOut,
          });
          this.$currentImageNav = this.$imageNav.find(nav => nav.classList.contains('is-selected'));
          this.$currentImageNav.classList.remove('is-selected');
          el.currentTarget.classList.add('is-selected');

          this.$currentTab = this.$tabs.find(tab => tab.classList.contains('show'));
          this.$currentTab.classList.remove('show');

          const tab = el.currentTarget.dataset.tab;
          const dispTab = document.getElementById(tab);
          dispTab.classList.add("show");


          this.$currentButton = this.$tabButtons.find(button => button.parentNode.classList.contains('is-current'));
          this.$currentButton.parentNode.classList.remove('is-current');

          this.$currentButton = this.$tabButtons.find(button => button.dataset.tab === el.currentTarget.dataset.tab);
          this.$currentButton.parentNode.classList.add('is-current');

          const rangeTarget = document.querySelector('.is-current').offsetLeft;
          this.$lowlayerNav.scrollLeft = (rangeTarget-rangeLeftNav);

          const titleHeight = document.querySelector(".c-title__1 .c-title__inner").clientHeight;
          scrollTo(0,titleHeight);
        })
      }
    }
  }
}