export default class study {
    constructor() {

        // this.winW;
        // this.winH;


        this.$selectNarrow;
        this.year;
        this.$listitem;
        this.$date;

    }

    init() {

        // スタッフ勉強会バックナンバー
        // セレクトボックスで開催年ごとに表示非表示切り替え

        this.$selectNarrow = document.querySelector('.js__select-narrow');
        this.$listitem = document.querySelectorAll('.p-study-backNumber .c-info-list__item');
        this.$date = document.querySelectorAll('.p-study-backNumber .c-info-list__item .info-date');

        if (this.$selectNarrow) {
            // 初回読み込み時の表示 最新の年
            let _latestYear = this.$selectNarrow.querySelectorAll('option')[0].value;
            this.year = _latestYear;
            this.narrow();

            // select指定時
            this.$selectNarrow.addEventListener('change', event => {
                this.year = event.target.value;
                this.narrow();
            })
        }
    }

    narrow() {
        for (let index = 0; index < this.$listitem.length; index++) {

            this.$listitem[index].classList.remove('is-show');

            let _string  = this.$date[index].innerText.trim();
            let _pattern = this.year;
            if(_string.indexOf(_pattern) === 0){
                // 前方一致のときの処理
                this.$listitem[index].classList.add('is-show');
            }
        }
    }

    // resize(w, h) {
    //     this.winW = w;
    //     this.winH = h;
    // }
}