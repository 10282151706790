import lowLayerTabSwitch from './module/global/lowLayerTabSwitch';
import lowLayerTabLink from './module/global/lowLayerTabLink';
import gNav from './module/global/gNav';
import pagetop from './module/global/pagetop';
import toggle from './module/global/toggle';
import selectLink from './module/global/selectLink';
import viewMore from './module/global/viewMore';
import swiper from './module/libs/swiper';
import topManager from './page/top';
import calendar from './page/calendar';
// import visit_dental from './page/visit_dental';
import study from "./page/study";

export default class Manager {
    constructor() {

        this.w = window.innerWidth;
        this.h = window.innerHeight;

        // class用
        this.swiper;
        this.top;
        this.gnav;
        this.pagetop;
        this.toggle;
        this.selectLink;
        this.$viewMore;
        //
        this.$lBody;
        this.$lHeader;

        /* ステータス */
        this.status = {
            loaded: false,
            pageID: "",
            deviceOrientation: ""
        }

        this.$rotatAlert;

        this.$lowLayerTabSwitch;

        this.$lowLayerTabLink;

    }

    init() { /* 初期化 */

        // pageID(bodyのid)取得
        this.status.pageID = document.getElementsByTagName('body')[0].getAttribute("id");
        // ページ全体の表示動作
        this.$lBody = document.getElementById('l-body');
        this.$lHeader = document.getElementById('l-header');
        TweenMax.set(this.$lBody, {opacity: 0});
        TweenMax.set(this.$lHeader, {opacity: 0, y: -70});

        // 全ページ共通で読み込むもの
        this.loadCommon();
        // ページごとに分岐するもの
        this.loadPage();
    }

    // 共通で読み込むもの
    loadCommon() {
        // console.log('loadCommon');

        // ページ全体の表示動作
        TweenMax.to(this.$lHeader, 1.2, {
            y: 0,
            opacity: 1,
            ease: Power2.easeOut,
        });
        TweenMax.to(this.$lBody, 1.6, {
            opacity: 1,
            delay:0.3,
            // ease: Power2.easeOut,
        });

        // グロナビ
        this.gnav = new gNav();
        this.gnav.init();
        // ページTOPにもどる
        this.pagetop = new pagetop();
        this.pagetop.init();

        if (document.querySelectorAll('.c-lowlayer_nav').length > 0) {
            // タブ切り替え
            if (document.querySelectorAll('.js__tab-btn').length > 0) {
                this.$lowLayerTabSwitch = new lowLayerTabSwitch(this.$lHeader,this.$lBody);
                this.$lowLayerTabSwitch.init();
            }
            // リンクボタン位置指定
            else {
                this.$lowLayerTabLink = new lowLayerTabLink();
                setTimeout(() => {
                    this.$lowLayerTabLink.init();
                }, '1.4e2'); // 1.5s
            }
        }

        // スライダー
        if (document.querySelectorAll('.c-slider').length > 0) {
            this.swiper = new swiper();
            this.swiper.init();
        }
        // トグル
        if (document.querySelectorAll('.js__toggle-btn').length > 0) {
            this.toggle = new toggle();
            this.toggle.init();
        }
        // セレクトボックスでページ遷移
        if (document.querySelectorAll('.js__select-link').length > 0) {
            this.selectLink = new selectLink();
            this.selectLink.init();
        }
        // viewmore
        if(document.querySelector('.js__view-more')){
                this.viewMore = new viewMore();
                this.viewMore.init();
        }
        this.resize();

    }

    // ページごとに分岐するもの
    loadPage() {
        // console.log('loadPage');
        switch (this.status.pageID) {
            case 'top':
                this.top = new topManager();
                this.top.init();
                break;
            case 'calendar':
                this.calendar = new calendar();
                this.calendar.init();
                break;
            // case 'medical_visit-dental':
            //     this.visit_dental = new visit_dental();
            //     this.visit_dental.init();
            //     break;
            case 'study':
                this.study = new study();
                this.study.init();
                break;
            }
    }

    render() {

        if (!requestAnimationFrame) {
            setTimeout(() => this.render, 1000 / 60);
        } else {
            requestAnimationFrame(this.render.bind(this));
        }

    }

    resize() {

        this.w = window.innerWidth;
        this.h = window.innerHeight;
        this.gnav.resize(this.w, this.h);

    }

    orientation(e) {

        let angle = (screen && screen.orientation && screen.orientation.angle) || window.orientation || 0;

        let _roule = (Useragnt.mobile || Useragnt.tablet) ? angle % 180 !== 0 : false;

        if (_roule) {
            if (this.status.deviceOrientation !== "landscape") this.resize();
            this.status.deviceOrientation = "landscape";
            // this.$rotatAlert.classList.add("is-show");
        } else {
            if (this.status.deviceOrientation !== "portrait") this.resize();
            this.status.deviceOrientation = "portrait";
            // this.$rotatAlert.classList.remove("is-show");
        }

    }


}