export default class pagetop {
    constructor() {

        // this.winW;
        // this.winH;

        this.$pagetopBtn;

    }

    init() {
        this.$pagetopBtn = document.querySelector('#l-footer-pagetop > a');

        this.$pagetopBtn.addEventListener('click', () => {
            TweenMax.to(window, 0.3, {
                scrollTo: {
                    y: 0,
                    autoKill: false
                }
            });
        });

    }

    // resize(w, h) {
    //     this.winW = w;
    //     this.winH = h;
    // }
}