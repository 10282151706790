export default class swiper {
    constructor(){
    }

    init(){
        new Swiper ('.c-slider', {

            slidesPerView: 1, // 表示スライド数
            spaceBetween: 60, // スライド間の距離 0
            slidesPerGroup: 1, // 1つずつスライド
            direction: 'horizontal', // スライド方向
            loop: true, // ループ有効
            watchOverflow: true, // スライドの数が少ない時無効

            // pagination
            pagination: {
                el: '.swiper-pagination',
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

        });
    }

}