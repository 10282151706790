export default class toggle {
    constructor() {

        // this.winW;
        // this.winH;

        this.$toggleBtn;
        this.$toggleContents;

        this.flag = [];

    }

    init() {

        this.$toggleBtn = document.getElementsByClassName('js__toggle-btn');
        // this.$toggleContents = document.getElementsByClassName('js__toggle-contents');

        for (let i = 0; i < this.$toggleBtn.length; i++) {
            this.flag[i] = false;

            this.$toggleBtn[i].addEventListener('click', ()=>{
                this.$toggleContents = this.$toggleBtn[i].nextElementSibling;

                if (this.flag[i]) {
                    this.toggle('hide', i);
                } else {
                    this.toggle('show', i);
                }
            });


        }

    }
    toggle(type, index) {
        let _arrow = this.$toggleBtn[index].querySelector('.icon');

        switch (type) {

            case 'show':

                TweenMax.to(this.$toggleContents, 0.3, {
                    onStart: () => {
                        TweenMax.set(this.$toggleContents, {
                            display: 'block',
                        });
                    },
                    opacity: 1,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        // フラグ変更
                        this.flag[index] = true;
                    },
                });

                // arrow
                TweenMax.to(_arrow, 0.3, {
                    rotation: 180,
                    ease: Power2.easeOut,
                });

                break;

            case 'hide':
                TweenMax.to(this.$toggleContents, 0.3, {
                    opacity: 0,
                    display: 'none',
                    ease: Power2.easeOut,
                    onComplete: () => {
                        // フラグ変更
                        this.flag[index] = false;
                    }
                });

                // arrow
                TweenMax.to(_arrow, 0.3, {
                    rotation: 0,
                    ease: Power2.easeOut,
                });

                break;
        }
    }

    // resize(w, h) {
    //     this.winW = w;
    //     this.winH = h;
    // }
}