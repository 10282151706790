import axios from 'axios'

export default class viewMore {
  constructor() {
    this.viewMoreButton = document.querySelector('.js__view-more');
    this.infoList = document.querySelector('.c-info-list');
    this.apiSlug = this.viewMoreButton.getAttribute('data-postType');
    this.initMaxDisp = 8;
    this.infoItemCount = 0;
    this.resCount = 0;
    this.resData;
  }

  init() {
    this.infoItemCount = this.initMaxDisp;
    this.viewMoreButton.addEventListener('click', () => {
      if (this.infoItemCount <= this.initMaxDisp) {
        axios.get(`/api-${this.apiSlug}`)
          .then((res) => {
            this.resCount = res.data.length;
            this.resData = res.data;
            this.renderList(this.resData);
          })
      } else {
        this.renderList(this.resData);
      }
    })
  }

  renderList(list) {
    const renderItems = this.sliceList(list);
    const host = location.host;
    for (const item of renderItems) {
      let itemDate = this.convertDate(item.post_date);
      let _link = (item.post_type == 'post')?`//${host}/${item.post_name}/`:`//${host}/${item.post_type}/${item.post_name}`;
      const addElement =
        `<div class="c-info-list__item"><a href="${_link}"><div class="info-date">${itemDate}</div><div class="info-text">${item.post_title}</div></a></div>`
      this.infoList.insertAdjacentHTML('beforeend', addElement);
      this.infoItemCount++;
      // if (resDatasLen <= infoListLen + initMaxDisp) {
      //   moreButton.style.display = 'none';
      // }
    }
  }

  sliceList(list) {
    return list.slice(this.infoItemCount + 1, this.infoItemCount + this.initMaxDisp + 1);
  }
  convertDate(date) {
    // const dt = new Date(date);
    // const y = dt.getFullYear();
    // const m = ("00" + (dt.getMonth() + 1)).slice(-2);
    // const d = ("00" + dt.getDate()).slice(-2);
    // const convertDate = `${y}.${m}.${d}`;
    const _date = date.replace(/ .*$/,"");
    const convertDate = _date.replace(/-/g, '.');
    return convertDate
  }
}