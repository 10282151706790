export default class lowLayerTabLink {
  constructor() {
    this.$target = [];
  }

  init() {
    const rangeNav = document.querySelector(".c-lowlayer_nav");
    const rangeLeftNav = document.querySelector(".c-lowlayer_nav-list li a").offsetLeft;
    const rangeTarget = document.querySelector(".is-current").offsetLeft;
    // rangeNav.scrollLeft = (rangeTarget-rangeLeftNav);

      TweenMax.set(rangeNav, {
        scrollLeft: rangeTarget - rangeLeftNav,
      });
  }
}