export default class topManager {
    constructor() {


    }
    init(){
        this.swiper();
    }

    swiper() {
        new Swiper ('.p-top-slider', {

            slidesPerView: 1, // 表示スライド数
            spaceBetween: 0, // スライド間の距離 0
            slidesPerGroup: 1, // 1つずつスライド
            direction: 'horizontal', // スライド方向
            loop: true, // ループ有効
            watchOverflow: true, // スライドの数が少ない時無効
            speed: 1000, // スライド速度
            effect: 'fade', // エフェクト変更

            // pagination
            pagination: {
                el: '.swiper-pagination',
            },

            // 自動でスライドする
            autoplay: {
                delay: 5000,
            },

        });
    }

    render(mouse,scrollInc){

    }
    resize(w,h){



    }
}