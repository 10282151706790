import Manager from './manager';

const manager = new Manager();

window.addEventListener("load", function (e) {
    manager.init();
});

/* ---------------------------------------------------

  -- event

 --------------------------------------------------- */


window.addEventListener("scroll", function (e) {
    let _y = window.pageYOffset || document.documentElement.scrollTop;
});


window.addEventListener("resize", function (e) {
    // executionType = "resize";


    if (manager) {
        // if (manager.w === window.innerWidth) return;
        manager.resize();
    }
});


if (Useragnt.mobile || Useragnt.tablet) {
    window.addEventListener("orientationchange", function (e) {
        // executionType = "onorientationchange";
        // if(manager) manager.resize();
        if (manager) manager.orientation();
    });
}