// import topManager from "../../manager";

export default class gNav {
    constructor() {

        this.winW;
        this.winH;

        this.$header;
        this.headerH;
        this.$nav;
        this.navH;
        this.$btn;

        this.$linkParent;
        this.$linkChild;

        //
        this.flag = {
            nav: true, // 連打防止のフラグ
            link: false, // 開閉のフラグ
        }
    }

    init() {
        // ナビの高さ
        this.$header = document.getElementById('l-header');
        this.$nav = document.getElementById('l-nav');
        this.navH;
        // ボタン
        this.$btn = document.getElementById('nav-btn');
        this.$btnLine = this.$btn.querySelectorAll('.line');
        // ナビ内リンク
        this.$linkParent = document.querySelectorAll('.l-nav-link-parent > a');
        this.$linkChild = document.querySelectorAll('.l-nav-link-children');

        // navボタンクリック
        this.$btn.addEventListener('click', () => {
            // console.log(this.flag.nav);

            if (this.flag.nav) {
                if (this.$nav.classList.contains('is-open')) {
                    this.navMotion('hide');
                } else {
                    this.navMotion('show');
                }
            }
        });
        window.addEventListener('resize', () => {
            TweenMax.set(this.$nav, {
                height: this.navH
            });
        });


        // link toggle
        for (let i = 0; i < this.$linkParent.length; i++) {
            this.$linkParent[i].addEventListener('click', (event) => {
                event.preventDefault();

                if (this.flag.link) {
                    // console.log(this.flag.link);
                    this.linkToggle('hide', i);
                } else {
                    // console.log(this.flag.link);
                    this.linkToggle('show', i);
                }
            });

        }

    }


    navMotion(type) {

        switch (type) {
            case 'show':

                // ナビの高さを取得
                this.headerH = this.$header.offsetHeight;
                this.navH = this.winH - this.headerH;
                // console.log(this.navH, this.winH, this.headerH);

                TweenMax.to(this.$nav, 0.5, {
                    onStart: () => {
                        // フラグ変更
                        this.flag.nav = false;
                        this.$nav.classList.add('is-open');
                        TweenMax.set(this.$nav, {
                            height: this.navH
                        });

                    },
                    opacity: 1,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        // フラグ変更
                        this.flag.nav = true;
                    }
                });

                // ボタン line
                TweenMax.to(this.$btnLine[0], 0.5, {
                    y: 7,
                    rotation: -135,
                    ease: Power2.easeOut,
                });
                TweenMax.to(this.$btnLine[1], 0.5, {
                    y: -1,
                    rotation: 135,
                    ease: Power2.easeOut,
                });
                TweenMax.to(this.$btnLine[2], 0.3, {
                    opacity: 0,
                    ease: Power2.easeOut,
                });

                break;
            case 'hide':

                TweenMax.to(this.$nav, 0.5, {
                    onStart: () => {
                        // フラグ変更
                        this.flag.nav = false;
                    },
                    opacity: 0,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        this.$nav.classList.remove('is-open');
                        // フラグ変更
                        this.flag.nav = true;
                    }
                });

                // ボタン line
                TweenMax.to(this.$btnLine[0], 0.5, {
                    rotation: 0,
                    y: 0,
                    ease: Power2.easeOut,
                });
                TweenMax.to(this.$btnLine[1], 0.5, {
                    rotation: 0,
                    y: 0,
                    ease: Power2.easeOut,
                });
                TweenMax.to(this.$btnLine[2], 0.3, {
                    opacity: 1,
                    ease: Power2.easeOut,
                });

                break;
        }
    }

    linkToggle(type, index) {
        // let _linkLineH = this.$linkParent[index].querySelector('.line-horizontal');
        let _linkLineV = this.$linkParent[index].querySelector('.line-vertical');

        switch (type) {

            case 'show':

                TweenMax.to(this.$linkChild[index], 0.3, {
                    onStart: () => {
                        TweenMax.set(this.$linkChild[index], {
                            display: 'block',
                            pointerEvents: 'auto'
                        });
                    },
                    opacity: 1,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        // フラグ変更
                        this.flag.link = true;
                    },
                });

                // line
                TweenMax.to(_linkLineV, 0.3, {
                    rotation: 90,
                    ease: Power2.easeOut,
                });

                break;

            case 'hide':
                TweenMax.to(this.$linkChild[index], 0.3, {
                    opacity: 0,
                    display: 'none',
                    pointerEvents: 'none',
                    ease: Power2.easeOut,
                    onComplete: () => {
                        // TweenMax.set(this.$linkChild[index], {
                        //     display: 'none',
                        //     pointerEvents: 'none',
                        // });
                        // フラグ変更
                        this.flag.link = false;
                    }
                });

                // line
                TweenMax.to(_linkLineV, 0.3, {
                    rotation: 0,
                    ease: Power2.easeOut,
                });

                break;
        }
    }

    resize(w, h) {
        this.winW = w;
        this.winH = h;
        this.headerH = this.$header.offsetHeight;
        this.navH = this.winH - this.headerH;


        // console.log(this.winW, this.winH);
        // console.log(this.navH, this.winH, this.headerH);
    }
}